<script>
  import Select from "svelte-select";
  import { createEventDispatcher } from "svelte";
  import { getClientsList } from "../api";
  import { currentClient, currentClientWebsite, currentUser } from "../stores";
  const dispatch = createEventDispatcher();
  const changeClient = (e) => {
    $currentClient = e.detail.id;
    $currentClientWebsite = e.detail.website;
    window.sessionStorage.setItem(
      "sb_last_client_viewed",
      JSON.stringify({ id: e.detail.id, name: e.detail.name, website: e.detail?.website })
    );

    dispatch("changeClient");
  };

  let initialValue = () => {
    let res = { id: $currentUser.clientId, name: $currentUser.clientName };

    if (window) {
      let lsLastClientViewed = window.sessionStorage.getItem("sb_last_client_viewed");
      if (lsLastClientViewed) res = JSON.parse(lsLastClientViewed);
      else window.sessionStorage.setItem("sb_last_client_viewed", JSON.stringify(res));
    }
    $currentClient = res.id;
    $currentClientWebsite = res.website;
    return res;
  };

  async function loadOptions(filterText) {
    if (!filterText) return [];
    let res = await getClientsList(filterText);
    return res;
  }
</script>

<div>
  {#await getClientsList() then list}
    <Select
      {loadOptions}
      items={list}
      value={initialValue()}
      optionIdentifier="website"
      placeholderAlwaysShown={true}
      labelIdentifier="name"
      placeholder={"Type to search..."}
      on:select={changeClient}
    />
  {/await}
</div>

<style>
  div {
    width: 100%;
  }
  @media (min-width: 640px) {
    div {
      max-width: 400px;
    }
  }
</style>
