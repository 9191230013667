<script>
  import { LogoLoader } from "@shopboxai/sb-portal-components";
  import { onMount } from "svelte";
  import { useNavigate, useLocation, useFocus } from "svelte-navigator";
  import { userToken, currentUser, loaderShown } from "../stores";
  import { asyncCheckAuthStatus } from "../api";

  export let keys = [];
  export let access = false;
  let roleCheck = false;
  let isChecking = true;

  const navigate = useNavigate();
  const location = useLocation();
  const registerFocus = useFocus();

  const navigateToLogin = () => {
    window.location.href = "/login";
    navigate("/login");
  };
  const navigateToHome = () => {
    navigate("/", {
      state: { from: $location.pathname },
      replace: true,
    });
  };

  onMount(async () => {
    try {
      let res = await asyncCheckAuthStatus(keys);
      roleCheck = $currentUser[access];
    } catch (err) {
      console.log(err);
      // navigateToLogin();
    } finally {
      if (!roleCheck) {
        navigateToHome();
      }
      isChecking = false;
      $loaderShown = true;
    }
  });
  $: if (!$userToken && !isChecking) {
    navigateToLogin();
  }
</script>

{#if $userToken && !isChecking && roleCheck}
  <slot {registerFocus} />
{/if}

{#if isChecking}
  {#if $loaderShown == false}
    <div class="page-loader"><LogoLoader /></div>
  {/if}
{/if}

<style>
  .page-loader {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }
  .page-loader :global(svg) {
    transform: scale(2);
  }
</style>
