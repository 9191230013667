import { writable } from "svelte/store";

export const loaderShown = writable(false);
export const userToken = writable(JSON.parse(localStorage.getItem("sb-portal-user")) || null);
export const currentClient = writable(null);
export const currentClientWebsite = writable(null);
export const currentUser = writable(null);

userToken.subscribe((value) => {
  localStorage.setItem("sb-portal-user", JSON.stringify(value));
});
