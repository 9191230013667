<script>
  import { routes } from "./routes";
  import { onMount, tick } from "svelte";
  import { userToken, currentUser } from "./stores";
  import { Router, Route, Link } from "svelte-navigator";
  import { Icon, NotificationDisplay } from "@shopboxai/sb-portal-components";
  import Logo from "./Components/Logo.svelte";
  import Login from "./routes/Login.svelte";
  import Help from "./routes/Help.svelte";
  import PrivateRoute from "./routes/PrivateRoute.svelte";
  import PasswordReset from "./routes/PasswordReset.svelte";
  import ClientSwitcher from "./Components/ClientSwitcher.svelte";

  let mounted = false;
  let mobileSidebarActive = false;

  function handleLogout() {
    $userToken = null;
    window.sessionStorage.removeItem("sb_last_client_viewed");
  }

  async function changeClient() {
    mounted = false;
    await tick();
    mounted = true;
  }

  onMount(async () => {
    mounted = true;
  });
</script>

{#if $currentUser && $userToken}
  <header id="main-header">
    <Logo />

    <button on:click={() => (mobileSidebarActive = !mobileSidebarActive)}>
      {#if mobileSidebarActive == true}
        <Icon name="close" />
      {:else}
        <Icon name="menu" />
      {/if}
    </button>
    <ClientSwitcher on:changeClient={changeClient} />
  </header>
{/if}

<NotificationDisplay />
{#if mounted}
  <div class="page-wrapper">
    <Router>
      {#if $currentUser && $userToken}
        <aside class:mobile-sidebar-visible={mobileSidebarActive}>
          {#each Object.keys($routes) as routeCategory}
            <div class="category" data-wording={routeCategory}>
              {#each $routes[routeCategory] as route}
                {#if $currentUser[route.permissions] == true}
                  <Link class="aside-link" to={route.path} on:click={() => (mobileSidebarActive = false)}
                    ><Icon name={route.icon} />{route.title}</Link
                  >
                {/if}
              {/each}
            </div>
          {/each}
          <div class="spacer" />
          <Link class="aside-link" to="help" on:click={() => (mobileSidebarActive = false)}><Icon name="help" />Help</Link>
          <a href=" " class="aside-link" on:click={handleLogout}> <Icon name="logout" />Log out</a>
        </aside>
      {/if}
      <main>
        <Route path="login" let:registerFocus>
          <Login />
        </Route>
        <Route path="reset" let:registerFocus>
          <PasswordReset />
        </Route>
        <Route path="embed/:id" let:params>
          {params.id}
        </Route>

        <PrivateRoute path="help" access="hasHelpPermission">
          <Help />
        </PrivateRoute>

        {#each Object.keys($routes) as routeCategory}
          {#each $routes[routeCategory] as route}
            <PrivateRoute path={route.path} access={route.permissions} let:location let:registerFocus>
              <svelte:component this={route.component} />
            </PrivateRoute>
          {/each}
        {/each}
      </main>
    </Router>
  </div>
{/if}

<style>
  main {
    padding: 1em;
    flex: 1;
    border-left: 1px solid #efefef;
    width: calc(100% - 200px);
  }
  @media (min-width: 640px) {
    .page-wrapper {
      display: flex;
      min-height: 100vh;
    }
  }
  @media (max-width: 640px) {
    main {
      width: 100%;
    }
  }
</style>
