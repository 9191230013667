<div class="logo">
  <svg width="150" height="29" viewBox="0 0 150 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="shopbox-ai 2" clip-path="url(#clip0_1139_5851)">
      <g id="Group">
        <g id="Group_2">
          <g id="Group_3">
            <g id="Group_4">
              <g id="XMLID_00000109750410512962881020000005319530364250441620_">
                <g id="Group_5">
                  <path id="Vector" d="M12.5391 5.70001L18.8691 9.39001H18.8991V29.97L12.5391 26.31V5.70001Z" fill="#FFB400"></path>
                  <g id="Group_6">
                    <path id="Vector_2" d="M15.4199 5.7L25.2899 0.0299988L28.7699 2.04L18.8699 7.74L15.4199 5.7Z" fill="#2FBFAB"></path>
                  </g>
                </g>
              </g>
              <path
                id="Vector_3"
                d="M18.8984 9.39001V29.97L31.6184 22.62V2.04001L18.8984 9.39001ZM20.6384 14.4C21.2084 14.07 21.9284 14.34 22.2284 14.94C22.3784 15.24 22.5584 15.45 22.7984 15.6C23.3384 15.9 24.1184 15.81 24.9584 15.33C26.3684 14.52 27.5984 12.84 28.2284 11.13C28.5584 10.23 29.1884 9.48001 30.0284 9.00001C30.0284 11.82 28.1084 15.12 25.6484 16.53C24.8984 16.95 24.1784 17.19 23.4884 17.19C22.9784 17.19 22.4984 17.07 22.0784 16.83C21.3284 16.35 20.8184 15.51 20.6384 14.4Z"
                fill="url(#paint0_linear_1139_5851)"
              ></path>
            </g>
            <path id="Vector_4" d="M6.21094 7.62009H6.24094L11.0409 10.4101V25.4401L6.21094 22.6501V7.62009Z" fill="#FFB400"></path>
            <path id="Vector_5" d="M0 9.29999L4.83 12.09V21.84L0 19.05V9.29999Z" fill="#FFB400"></path>
          </g>
        </g>
        <g id="Group_7">
          <path
            id="Vector_6"
            d="M45.06 20.25C44.07 19.92 43.32 19.47 42.78 18.87C42.27 18.27 42 17.58 42 16.77C42 15.57 42.81 14.94 44.46 14.94C44.52 16.05 44.91 16.92 45.6 17.49C46.29 18.06 47.28 18.36 48.57 18.36C49.62 18.36 50.43 18.18 51 17.82C51.57 17.46 51.87 16.95 51.87 16.29C51.87 15.87 51.75 15.51 51.54 15.24C51.33 14.97 50.94 14.73 50.4 14.52C49.86 14.31 49.08 14.1 48.06 13.86C46.62 13.53 45.48 13.14 44.64 12.72C43.8 12.3 43.2 11.82 42.84 11.22C42.48 10.65 42.3 9.89999 42.3 9.02999C42.3 8.18999 42.54 7.43999 43.05 6.80999C43.56 6.17999 44.25 5.66999 45.15 5.30999C46.05 4.94999 47.1 4.76999 48.3 4.76999C49.44 4.76999 50.46 4.91999 51.36 5.21999C52.26 5.51999 52.95 5.93999 53.46 6.50999C53.97 7.04999 54.24 7.67999 54.24 8.39999C54.24 9.47999 53.46 10.05 51.87 10.11C51.78 9.08999 51.45 8.33999 50.88 7.85999C50.31 7.37999 49.47 7.13999 48.3 7.13999C47.31 7.13999 46.56 7.28999 46.02 7.61999C45.48 7.94999 45.21 8.39999 45.21 8.99999C45.21 9.41999 45.3 9.74999 45.51 10.02C45.72 10.29 46.05 10.53 46.56 10.74C47.04 10.95 47.73 11.13 48.63 11.34C50.16 11.67 51.36 12.06 52.23 12.48C53.13 12.9 53.76 13.41 54.15 14.01C54.54 14.61 54.75 15.33 54.75 16.23C54.75 17.13 54.48 17.91 53.97 18.6C53.46 19.26 52.74 19.8 51.81 20.16C50.88 20.52 49.77 20.7 48.51 20.7C47.22 20.73 46.05 20.58 45.06 20.25Z"
            fill="black"
          ></path>
          <path
            id="Vector_7"
            d="M56.5508 6.96008C56.5508 6.27008 56.7908 5.76008 57.2708 5.40008C57.7508 5.04008 58.4108 4.86008 59.3108 4.86008V10.6201C59.1308 10.8301 58.9508 11.0701 58.7708 11.3401L58.8908 11.4301C59.9108 10.0201 61.2008 9.30008 62.7908 9.30008C63.5408 9.30008 64.2008 9.48008 64.8008 9.81008C65.3708 10.1401 65.8208 10.6201 66.1508 11.2201C66.4808 11.8201 66.6308 12.4801 66.6308 13.2301V19.1401C66.6308 19.7401 66.3908 20.1601 65.9408 20.4301C65.4908 20.7001 64.8008 20.8201 63.8708 20.8201V13.6201C63.8708 13.0501 63.6908 12.6001 63.3608 12.2401C63.0308 11.8801 62.5508 11.7001 61.9208 11.7001C61.1708 11.7001 60.5408 11.9401 60.0608 12.3901C59.5808 12.8701 59.3408 13.4401 59.3408 14.1301V19.1101C59.3408 19.7101 59.1008 20.1301 58.6508 20.4001C58.2008 20.6701 57.5108 20.7901 56.5808 20.7901V6.96008H56.5508Z"
            fill="black"
          ></path>
          <path
            id="Vector_8"
            d="M70.9197 20.22C70.1397 19.86 69.5397 19.38 69.0897 18.78C68.6397 18.18 68.4297 17.46 68.4297 16.68V13.53C68.4297 12.72 68.6397 12 69.0897 11.37C69.5397 10.71 70.1397 10.2 70.8897 9.83999C71.6697 9.44999 72.5397 9.26999 73.5297 9.26999C74.5197 9.26999 75.3897 9.44999 76.1697 9.83999C76.9497 10.23 77.5497 10.74 77.9997 11.37C78.4497 12.03 78.6597 12.75 78.6597 13.53V16.65C78.6597 17.43 78.4197 18.15 77.9697 18.75C77.5197 19.38 76.9197 19.86 76.1397 20.19C75.3597 20.52 74.4897 20.7 73.5297 20.7C72.5697 20.73 71.6997 20.58 70.9197 20.22ZM75.2697 17.85C75.6897 17.52 75.8997 17.04 75.8997 16.47V13.65C75.8997 13.05 75.6897 12.54 75.2697 12.18C74.8497 11.79 74.2797 11.61 73.5297 11.61C72.8097 11.61 72.2397 11.79 71.7897 12.18C71.3697 12.57 71.1597 13.05 71.1597 13.65V16.5C71.1597 17.07 71.3697 17.55 71.7897 17.88C72.2097 18.21 72.8097 18.39 73.5297 18.39C74.2497 18.36 74.8497 18.21 75.2697 17.85Z"
            fill="black"
          ></path>
          <path
            id="Vector_9"
            d="M80.5495 11.16C80.5495 10.62 80.7595 10.17 81.1795 9.80999C81.5995 9.44999 82.2295 9.26999 83.0995 9.26999V10.47C82.8595 10.8 82.7095 11.01 82.6795 11.07L82.7695 11.16C83.2495 10.53 83.7595 10.08 84.3295 9.74999C84.8995 9.41999 85.6195 9.26999 86.4595 9.26999C87.2695 9.26999 87.9895 9.44999 88.6195 9.77999C89.2495 10.11 89.7295 10.59 90.0895 11.19C90.4495 11.79 90.6295 12.48 90.6295 13.26V16.77C90.6295 17.58 90.4195 18.27 90.0295 18.87C89.6395 19.47 89.0995 19.95 88.4095 20.28C87.7195 20.61 86.9695 20.76 86.0995 20.76C85.5595 20.76 84.9895 20.64 84.3895 20.43C83.8195 20.19 83.3095 19.89 82.9195 19.5L82.8295 19.62C82.9195 19.77 83.0995 19.92 83.3095 20.1V23.37C83.3095 24.6 82.3795 25.23 80.5195 25.23V11.16H80.5495ZM87.2695 17.88C87.6595 17.58 87.8695 17.13 87.8695 16.56V13.44C87.8695 12.87 87.6595 12.42 87.2695 12.12C86.8795 11.82 86.3095 11.67 85.5595 11.67C84.8995 11.67 84.3595 11.82 83.9095 12.15C83.4895 12.48 83.2795 12.87 83.2795 13.35V16.71C83.2795 17.16 83.4895 17.55 83.9095 17.88C84.3295 18.21 84.8695 18.36 85.5595 18.36C86.3095 18.36 86.8795 18.18 87.2695 17.88Z"
            fill="black"
          ></path>
          <path
            id="Vector_10"
            d="M92.5508 6.69005C92.5508 6.09005 92.7908 5.64005 93.2408 5.31005C93.7208 4.98005 94.4108 4.83005 95.3408 4.83005V9.99005C95.1308 10.14 94.9808 10.29 94.8608 10.44L94.9508 10.56C95.3408 10.17 95.8808 9.87005 96.5108 9.63005C97.1408 9.39005 97.7408 9.30005 98.3108 9.30005C99.6308 9.30005 100.681 9.66005 101.461 10.41C102.241 11.13 102.631 12.09 102.631 13.29V16.83C102.631 17.61 102.451 18.3 102.061 18.9C101.671 19.5 101.161 19.98 100.501 20.31C99.8408 20.64 99.1508 20.82 98.4308 20.82C97.6508 20.82 96.9608 20.67 96.3908 20.34C95.8208 20.01 95.2808 19.56 94.8008 18.93L94.7108 19.02C94.7708 19.11 94.8308 19.23 94.9208 19.32C95.0108 19.41 95.0708 19.5 95.1308 19.56C95.1308 19.98 94.9208 20.28 94.5308 20.49C94.1408 20.7 93.4808 20.79 92.5808 20.79V6.69005H92.5508ZM99.2708 17.88C99.6608 17.58 99.8708 17.13 99.8708 16.53V13.41C99.8708 12.84 99.6608 12.42 99.2708 12.12C98.8808 11.82 98.3108 11.67 97.5908 11.67C96.9308 11.67 96.3908 11.82 95.9708 12.15C95.5208 12.48 95.3108 12.87 95.3108 13.32V16.68C95.3108 17.16 95.5208 17.58 95.9408 17.88C96.3608 18.21 96.9308 18.36 97.5908 18.36C98.3108 18.36 98.8808 18.21 99.2708 17.88Z"
            fill="black"
          ></path>
          <path
            id="Vector_11"
            d="M106.951 20.22C106.171 19.86 105.571 19.38 105.121 18.78C104.671 18.15 104.461 17.46 104.461 16.68V13.53C104.461 12.72 104.671 12 105.121 11.37C105.571 10.71 106.171 10.2 106.921 9.83999C107.701 9.44999 108.571 9.26999 109.561 9.26999C110.551 9.26999 111.421 9.44999 112.201 9.83999C112.981 10.23 113.581 10.74 114.031 11.37C114.481 12.03 114.691 12.75 114.691 13.53V16.65C114.691 17.43 114.451 18.15 114.001 18.75C113.551 19.38 112.951 19.86 112.171 20.19C111.391 20.52 110.521 20.7 109.561 20.7C108.601 20.73 107.731 20.58 106.951 20.22ZM111.301 17.85C111.721 17.52 111.931 17.04 111.931 16.47V13.65C111.931 13.05 111.721 12.54 111.301 12.18C110.881 11.79 110.311 11.61 109.561 11.61C108.841 11.61 108.271 11.79 107.821 12.18C107.401 12.57 107.191 13.05 107.191 13.65V16.5C107.191 17.07 107.401 17.55 107.821 17.88C108.241 18.21 108.841 18.39 109.561 18.39C110.311 18.36 110.881 18.21 111.301 17.85Z"
            fill="black"
          ></path>
          <path
            id="Vector_12"
            d="M115.681 20.61C115.381 20.52 115.141 20.37 114.961 20.19L118.861 14.79L115.321 9.65996C115.441 9.53996 115.651 9.41996 115.951 9.35996C116.251 9.29996 116.521 9.23996 116.791 9.23996C117.571 9.23996 118.111 9.47996 118.411 9.92996L120.331 12.96L119.881 13.59L120.031 13.71L122.551 9.92996C122.821 9.47996 123.391 9.23996 124.261 9.23996C124.501 9.23996 124.771 9.26996 125.041 9.35996C125.311 9.41996 125.521 9.53996 125.641 9.65996L122.071 14.79L126.001 20.19C125.821 20.37 125.611 20.52 125.311 20.61C125.041 20.7 124.771 20.73 124.501 20.73C124.201 20.73 123.901 20.67 123.601 20.55C123.301 20.43 123.091 20.28 122.971 20.13L120.451 16.44L117.961 20.13C117.841 20.31 117.631 20.46 117.361 20.58C117.091 20.67 116.821 20.73 116.521 20.73C116.251 20.73 115.981 20.7 115.681 20.61Z"
            fill="black"
          ></path>
          <path
            id="Vector_13"
            d="M131.28 20.07C130.92 19.71 130.74 19.23 130.74 18.66C130.74 18.24 130.83 17.79 131.04 17.34L136.11 5.78999C136.38 5.12999 136.89 4.79999 137.58 4.79999C138.27 4.79999 138.75 5.12999 139.02 5.78999L144.09 17.34C144.3 17.82 144.39 18.27 144.39 18.66C144.39 19.23 144.21 19.68 143.85 20.07C143.49 20.43 142.98 20.67 142.32 20.73L140.46 16.53H134.64L132.75 20.73C132.15 20.67 131.67 20.46 131.28 20.07ZM139.59 14.01L137.16 7.91999L136.89 8.03999L137.43 9.41999L135.57 14.01H139.59Z"
            fill="#2FBFAB"
          ></path>
          <path
            id="Vector_14"
            d="M147.029 7.05005C147.029 6.36005 147.299 5.82005 147.809 5.43005C148.319 5.04005 149.069 4.83005 149.999 4.83005V18.54C149.999 19.26 149.729 19.8 149.219 20.19C148.709 20.58 147.959 20.76 147.029 20.76V7.05005Z"
            fill="#2FBFAB"
          ></path>
        </g>
      </g>
    </g>
    <defs>
      <linearGradient id="paint0_linear_1139_5851" x1="25.2591" y1="6.18268" x2="25.2591" y2="27.0564" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFB400"></stop>
        <stop offset="1" stop-color="#FF469A"></stop>
      </linearGradient>
      <clipPath id="clip0_1139_5851">
        <rect width="150" height="30" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
</div>

<style>
  .logo {
    margin-bottom: 2em;
  }
</style>
