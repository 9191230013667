import { get } from "svelte/store";
import { User } from "./user";
import { userToken, currentClient, currentUser, currentClientWebsite } from "./stores";

export const url = () => {
  // console.log(process);
  // return process["env"].AUTH_URL;
  return process["env"].PROD_ENV == true || process["env"].PROD_ENV == "true"
    ? "https://sb-auth-jtdpxp3bfa-ew.a.run.app"
    : "https://beta-sb-auth-vlwut6qbsq-ew.a.run.app";
};
export const loginWithEmail = (email, password) => {
  return fetch(url() + "/api/v2/login/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `username=${email}&password=${encodeURIComponent(password)}`,
  }).then((res) => res.json());
};
export const registerUser = (email, password) => {
  fetch(url() + "/api/v1/users/", {
    body: `{ "password": "${password}", "email": "${email}"}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
  });
};
export const sendResetPasswordEmail = (email) => {
  return fetch(url() + "/api/v1/password-recovery/" + email, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};
export const saveNewPassword = (password, token) => {
  return fetch(url() + "/api/v1/reset-password/", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(password),
  }).then((res) => res.json());
};

export const getToken = () => {
  return get(userToken).access_token ? get(userToken).access_token : null;
};

export const getClientsList = async (q) => {
  let endpoint = `/api/v2/clients/?page=1&q=${q}`;
  if (!q) endpoint = `/api/v2/clients/?page=1&limit=19`;
  const clients = await fetch(url() + endpoint, {
    method: "GET",
    headers: { Authorization: `Bearer ${getToken()}`, "Content-Type": "application/json" },
  });

  if (clients.ok) {
    const clientsJSON = await clients.json();
    let clientList = [];
    for (const key in clientsJSON) {
      let separator = clientsJSON[key].id == "tony_kealys" ? "_" : "-";
      let websitesFormatted = clientsJSON[key].websites.map((w) => {
        return {
          id: w.client_id,
          website: w.client_id + separator + w.attr,
          name: clientsJSON[key].name + " " + w.name,
          features: clientsJSON[key].features,
          created_at: clientsJSON[key].created_at,
        };
      });
      clientsJSON[key].website = clientsJSON[key].id;
      clientList = [clientsJSON[key], ...websitesFormatted, ...clientList];
    }
    return clientList;
  }
  return [];
};
export const asyncCheckAuthStatus = async (routeKeys) => {
  try {
    const userInfoJSON = await fetchUserInfo();
    await handleLastClientViewed();
    const permissions = await fetchPermissions(userInfoJSON.id);
    const newUser = await createNewUser(userInfoJSON, routeKeys, permissions);
    currentUser.set(newUser);
    return get(currentUser);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchUserInfo = async () => {
  const response = await fetch(url() + `/api/v2/users/me`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

  if (!response.ok) {
    userToken.set(null);
    throw new Error("User not authenticated");
  }

  return await response.json();
};

const handleLastClientViewed = async () => {
  let lsLastClientViewed = window.sessionStorage.getItem("sb_last_client_viewed");
  if (lsLastClientViewed) lsLastClientViewed = JSON.parse(lsLastClientViewed);
  if (lsLastClientViewed && lsLastClientViewed.id) currentClient.set(lsLastClientViewed.id);
  if (lsLastClientViewed && lsLastClientViewed.website) currentClientWebsite.set(lsLastClientViewed.website);

  if (!lsLastClientViewed) {
    const clientsAccessJSON = await fetchClientsAccess();
    currentClient.set(clientsAccessJSON[0].id);
  }
};

const fetchClientsAccess = async () => {
  const response = await fetch(url() + "/api/v2/clients/?page=1&limit=1", {
    method: "GET",
    headers: { Authorization: `Bearer ${getToken()}`, "Content-Type": "application/json" },
  });

  if (!response.ok) {
    userToken.set(null);
    throw new Error("User has no access to clients");
  }

  return await response.json();
};

const fetchPermissions = async (userId) => {
  const response = await fetch(url() + `/api/v2/clients/${get(currentClient)}/users/${userId}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch user permissions");
  }

  return await response.json();
};

const fetchReportsV2Config = async (cid) => {
  try {
    const response = await fetch(`https://storage.googleapis.com/sb-config-store/frontend-configs/${cid}.json`);
    if (!response.ok) {
      return false;
    }
    const json = await response.json();
    return json.reportsV2 ? true : false;
  } catch (error) {
    return false;
  }
};

const createNewUser = async (userInfoJSON, routeKeys, permissions) => {
  const reportsV2 = await fetchReportsV2Config(get(currentClient));
  return new User({
    clientId: get(currentClient),
    routeKeys: routeKeys,
    accessToken: getToken(),
    email: userInfoJSON.email,
    permissions: permissions.permissions,
    isShopBoxAdmin: userInfoJSON.shopbox_admin,
    clientFeatures: permissions.client.features,
    clientName: permissions.client.name,
    website: get(currentClientWebsite),
    reportsV2: reportsV2,
  });
};
