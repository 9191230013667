<script>
  import { notifications } from '@shopboxai/sb-portal-components';
  import { useNavigate, useLocation } from 'svelte-navigator';
  import { saveNewPassword } from '../api';
  export let admin = false;
  export let key = false;
  let valid = false;
  let tipVisible = false;

  const navigate = useNavigate();
  const location = useLocation();

  const navigateToLogin = () => {
    navigate('/login', {
      state: { from: $location.pathname },
      replace: true,
    });
  };

  const isPasswordSecure = (password) => {
    const re = new RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.{6,})');
    return re.test(password);
  };
  function isPasswordValid() {
    if (isPasswordSecure(password)) {
      valid = true;
      tipVisible = false;
    } else {
      valid = false;
      tipVisible = true;
    }
  }
  let password;
  const reset = async () => {
    if (password) {
      try {
        const urlParams = new URLSearchParams($location.search);
        let token = urlParams.get('token');
        let res = await saveNewPassword(password, token);
        notifications.success('Password Reset Successfully', 5000);
        navigateToLogin();
      } catch (error) {
        notifications.danger(
          'Password reset token expired please request a new one <a href="/login?reset=true" style="text-decoration:underline !important;">here</a>',
          30000
        );
      }
    }
  };
</script>

<div class="login-wrapper">
  <form on:submit|preventDefault={reset}>
    <h1>Set your password</h1>
    <br />
    <input type="password" bind:value={password} on:input={isPasswordValid} required placeholder="Enter password" />
    <p class="form-tip" class:visible={tipVisible}>
      * Password must contain at least one letter, one number and be at least 6 characters long.
    </p>
    <button class="primary" type="submit" disabled={!valid}>Save your password</button>
  </form>
</div>

<style>
  :global(.sb-login) {
    padding: 0 !important;
  }
  .login-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    margin: -1em;
  }

  .login-wrapper form {
    flex-basis: 800px;
    padding: 2em;
  }
  input:not(input[type='checkbox']) {
    display: block;
    width: 100%;
    padding: 16px 20px;
    margin: 0px;
    border: 2px solid rgb(223, 223, 227);
    border-radius: 0px 5px 5px;
    outline: 0px;
    box-shadow: none;
    background-color: rgb(255, 255, 255);
    color: rgb(68, 74, 87);
    transition: border-color 0.2s ease 0s;
    position: relative;
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 1em;
  }

  .form-tip {
    color: var(--t-c);
    opacity: 0;
    font-size: 0.9rem;
    font-style: italic;
    margin-bottom: 0.5rem;
  }
  p.visible {
    opacity: 1;
  }
</style>
