export class User {
  constructor({
    clientId,
    accessToken,
    permissions,
    isShopBoxAdmin,
    clientFeatures,
    routeKeys = [],
    clientName,
    email,
    website,
    reportsV2,
  }) {
    this.clientId = clientId;
    this.accessToken = accessToken;
    this.permissions = permissions;
    this.isShopBoxAdmin = isShopBoxAdmin;
    this.clientFeatures = clientFeatures;
    this.routeKeys = routeKeys;
    this.clientName = clientName;
    this.email = email;
    this.fullClientId = website ? website : clientId;
    this.reportsV2 = reportsV2;
  }

  get hasAdminPermissions() {
    return this.permissions.admin || this.hasShopboxAdminPermissions;
  }
  get hasShopboxAdminPermissions() {
    return this.isShopBoxAdmin;
  }
  get hasReportsV2() {
    return this.reportsV2;
  }
  get hasApiPermissions() {
    return this.hasAdminPermissions || this.permissions.api_keys || this.hasShopboxAdminPermissions;
  }
  get hasOpenApiAccess() {
    return (this.permissions.api_keys && this.clientFeatures.open_api) || this.hasAdminPermissions;
  }
  get hasHomePermission() {
    return Object.keys(this.permissions).length > 0 || this.hasShopboxAdminPermissions;
  }
  get hasHelpPermission() {
    return Object.keys(this.permissions).length > 0 || this.hasShopboxAdminPermissions;
  }
  get hasWidgetCustomizationPermission() {
    return this.clientFeatures.widget_customization || this.hasShopboxAdminPermissions;
  }
  get hasGoLivePermission() {
    return this.clientFeatures.go_live || this.hasShopboxAdminPermissions;
  }
  get hasGridBuilderPermission() {
    return (this.permissions.grid_builder && this.clientFeatures.grid_builder) || this.hasShopboxAdminPermissions;
  }
}
